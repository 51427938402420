<template>
  <v-footer>
    <v-divider></v-divider>    
    <div>&copy; <b>iCheck</b> {{ new Date().getFullYear() }}</div>
  </v-footer>
</template>

<script>
  export default {
    data(){
     return{    
      icons: [
        'public',
        'fab fa-facebook',
        'fab fa-twitter',
        'fab fa-google-plus',
        'fab fa-linkedin',
        'fab fa-instagram'
      ]
     }
   }
  }
</script>


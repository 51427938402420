<template>
<div>
  <v-app>   
    <v-main v-if="$route.name=='Login'" class="logback">
      <defaultLayout></defaultLayout>        
          <!-- <v-content> -->            
          <v-container> 
            <!-- Print views -->  
              <router-view/>                  
          </v-container>      
            <!-- Start Page Loading dialog -->    
          <v-dialog
              v-model="loading.estado"
              hide-overlay
              persistent
              width="300"
            >
              <v-card
                :color="loading.color"
                dark
              >
                <v-card-text>
                  {{loading.titulo}}
                  <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
          </v-dialog>    
      <!-- </v-content>  -->
    </v-main>  
    <v-main v-else>    
      <defaultLayout></defaultLayout>        
        <!-- <v-content> -->            
        <v-container>                   
            <!-- Print views -->      
            <router-view/>
        </v-container>      
          <!-- Start Page Loading dialog -->    
        <v-dialog
            v-model="loading.estado"
            hide-overlay
            persistent
            width="300"
          >
            <v-card
              :color="loading.color"
              dark
            >
              <v-card-text>
                {{loading.titulo}}
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
        </v-dialog>    
    <!-- </v-content>  -->
    </v-main>
  </v-app>
  <Footer />
</div>
</template>

<script>
import defaultLayout from "@/layouts/defaultLayout"
// import ToolTop from "@/components/ToolTop";
// import Breadcrumbs from '@/components/Breadcrumbs';
import Footer from "@/components/Footer";
import {mapState} from "vuex";

export default {
  name: 'App',
  components: {
      defaultLayout,
      Footer
  },
  computed:{
    ...mapState(["loading"])    
  },
  methods:{    
  },
  mounted(){
      this.$store.dispatch('loadCurrentUser');
  }
}
</script>

<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
@import './assets/css/styles.css';
*/
@import './assets/themes/materialize/materialize.css';
@import './assets/themes/materialize/style.css';
@import './assets/themes/materialize/prism.css';
/*@import './assets/themes/materialize/flag-icon.min.css';*/
@import './assets/themes/materialize/perfect-scrollbar.css';
@import './assets/fonts/awesome/4.3.0/css/font-awesome.css';
@import './assets/themes/materialize/plugins/jgritter/css/jquery.gritter.css';
@import './assets/themes/materialize/plugins/alertify/css/alertify.core.css';
@import './assets/themes/materialize/plugins/alertify/css/alertify.default.css';
@import './assets/themes/materialize/plugins/sweetalert/sweetalert.css';
@import './assets/themes/materialize/custom.css';

@import url("https://cdn.syncfusion.com/ej2/material.css");
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    //component: Home //En esta forma tenemos que importarlo arriba
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "home" */ '../views/Login.vue')
  },
  {
    path: '/pos',
    name: 'POS',
    component: () => import(/* webpackChunkName: "home" */ '../views/POS.vue')
  },
  {
    path: '/registro',
    name: 'Registration',
    component: () => import(/* webpackChunkName: "home" */ '../views/Registration.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import(/* webpackChunkName: "home" */ '../views/Forget.vue')
  },
  {
    path: '/usuarios',
    name: 'User',
    component: () => import(/* webpackChunkName: "home" */ '../views/User.vue')
  },
  {
    path: '/settings',
    name: 'Setting',
    component: () => import(/* webpackChunkName: "home" */ '../views/Setting.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/marcas',
    name: 'Brand',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Brand.vue')
   },
   {
    path: '/medidas',
    name: 'Measurement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Measurement.vue')
   },
   {
    path: '/familias',
    name: 'Family',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Family.vue')
   },
   {
    path: '/subfamilias',
    name: 'Subfamily',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Subfamily.vue')
   },
  {
    path: '/campaign-add',
    name: 'CampaignAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/CampaignAdd.vue')
   },
   {
    path: '/campaigns',
    name: 'Campaign',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Campaign.vue')
   },
   {
    path: '/carrito',
    name: 'Car',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Car.vue')
   },
   {
    path: '/crypto',
    name: 'Crypto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Crypto.vue')
   },
  {
    path: '/compras',
    name: 'Purchase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Purchase.vue')
   },
   {
    path: '/purchase-add',
    name: 'PurchaseAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/PurchaseAdd.vue')
   },
   {
    path: '/detallecompra',
    name: 'DetailsPurchase',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/DetailsPurchase.vue')
   },
   {
    path: '/eventos',
    name: 'Event',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Event.vue')
   },
   {
    path: '/proyectos',
    name: 'Project',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Project.vue')
   },
   {
    path: '/gastos',
    name: 'Expensive',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Expensive.vue')
   },
   {
    path: '/pagos',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Payment.vue')
   },
   {
    path: '/payment-add',
    name: 'PaymentAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/PaymentAdd.vue')
   },
   {
    path: '/facturas',
    name: 'Invoice',
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Invoice.vue')
   },
   {
    path: '/detallefactura',
    name: 'DetailsInvoice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/DetailsInvoice.vue')
   },
   {
    path: '/ventas',
    name: 'SalesOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/SalesOrder.vue')
   },   
   {
    path: '/detalleventas',
    name: 'DetailsSalesOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/DetailsSalesOrder.vue')
   },
   {
    path: '/salesorder-add',
    name: 'SalesOrderAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/SalesOrderAdd.vue')
   },
   {
    path: '/devoluciones',
    name: 'Return',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Return.vue')
   },
   {
    path: '/detalledevolucion',
    name: 'DetailsReturn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/DetailsReturn.vue')
   },
   {
    path: '/precios',
    name: 'PriceList',
    component: () => import(/* webpackChunkName: "expensive" */ '../views/PriceList.vue')
   },
   {
    path: '/preciosenlista',
    name: 'PriceListViewList',
    component: () => import(/* webpackChunkName: "expensive" */ '../views/PriceListViewList.vue')
   },
   {
    path: '/pw-product',
    name: 'PWProduct',
    component: () => import(/* webpackChunkName: "expensive" */ '../views/PWProduct.vue')
   }, 
   {
    path: '/pwproduct-add',
    name: 'PWProductAdd',
    component: () => import(/* webpackChunkName: "expensive" */ '../views/PWProductAdd.vue')
   },
   {
    path: '/pwproductcollate-add',
    name: 'PWCollateProductAdd',
    component: () => import(/* webpackChunkName: "expensive" */ '../views/PWCollateProductAdd.vue')
   },
   {
    path: '/pw-prices',
    name: 'PWPrices',
    component: () => import(/* webpackChunkName: "expensive" */ '../views/PWPrices.vue')
   },
   {
    path: '/reporte-mensual',
    name: 'ReportMonth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/ReportMonth.vue')
   },
   {
    path: '/plugins',
    name: 'Plugin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Plugin.vue')
   },
   {
    path: '/proveedores',
    name: 'Supplier',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Supplier.vue')
   },
   {
    path: '/proveedores-add',
    name: 'SupplierAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/SupplierAdd.vue')
   },
   {
    path: '/empleados',
    name: 'Employee',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Employee.vue')
   },
   {
    path: '/employee-add',
    name: 'EmployeeAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/EmployeeAdd.vue')
   },
  //  {
    // path: '/sync-mailchimp',
    // name: 'MailchimpSync',
    // component: () => import(/* webpackChunkName: "expensive" */ '../views/MailchimpSync.vue')
  //  },
   {
    path: '/detalleventascliente',
    name: 'CustomerSaleOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/CustomerSaleOrder.vue')
   },
   {
    path: '/detalleventasproducto',
    name: 'ProductSaleOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/ProductSaleOrder.vue')
   },
   {
    path: '/detalleproyecto',
    name: 'DetailProject',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/DetailProject.vue')
   },
   {
    path: '/clientes',
    name: 'Customer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Customer.vue')
   },
   {
    path: '/quotes',
    name: 'Quote',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Quote.vue')
   },
   {
    path: '/quote-add',
    name: 'QuoteAdd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/QuoteAdd.vue')
   },
   {
    path: '/detallecotizacion',
    name: 'DetailsQuote',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/DetailsQuote.vue')
   },
   {
    path: '/mensajes',
    name: 'Message',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "expensive" */ '../views/Message.vue')
   },
   {
    path: '/plugin-add',
    name: 'PluginAdd',
    component: () => import('../views/PluginAdd.vue')
   },
   {
    path: '/lead-add',
    name: 'LeadAdd',    
    component: () => import(/* webpackChunkName: "expensive" */ '../views/LeadAdd.vue')
   },
   {
    path: '/test',
    name: 'Test',    
    component: () => import('../views/Test.vue')
   },
   {
    path: '/chat',
    name: 'Pusher',    
    component: () => import('../views/Pusher.vue')
   },
   {
    path: "/:catchAll(.*)",
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
   },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
    <div v-if="$route.name=='Login'"></div>
    <div v-else-if="$route.name=='POS'"></div>
    <div v-else>     
      <ToolTop />                    
      <Breadcrumbs></Breadcrumbs>
    </div>
</template>

<script>
import {mapState} from "vuex";
//import SearchProducts from "./components/SearchProducts.vue";
import ToolTop from "@/components/ToolTop";
import Breadcrumbs from '@/components/Breadcrumbs';
// import Footer from "@/components/Footer";

export default{
    name:'defaultLayout',
    computed:{
        ...mapState(["loading",'currentUser'])
    },
    components:{    
        //Productos,
        ToolTop,
        Breadcrumbs,
        // Footer,
        //SearchProducts
    }
}
</script>
<template>
 <div id="notify">
  <v-menu 
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
  <template v-slot:activator="{ on, attrs }">
      <v-badge
         color="green"
         :content=notifications.length
         overlap
         offset-x="21"
         offset-y="21"
      >
        <v-btn
          icon
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>notifications</v-icon>
        </v-btn>
      </v-badge>
    </template>    
      <template>
      <v-card
        class="mx-auto"
        max-width="500"
      >
        <v-toolbar
          color="deep-purple accent-4"
          dark
        >

          <v-toolbar-title>Notificaciones</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn icon>
            <v-icon>drafts</v-icon>
          </v-btn>
        </v-toolbar>

        <v-list subheader>
          <v-subheader>Recientes Mensajes</v-subheader>
          <v-list-item
            v-for="chat in notifications"
            :key="chat.title"
          >
            <v-list-item-avatar>
              <v-img
                alt="avatar"
                src="/no_picture.png"
              ></v-img>
              <!-- :alt="`${chat.title} avatar`" -->
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="chat.name"></v-list-item-title>
              <v-list-tile-sub-title v-text="chat.fecha_inicio"></v-list-tile-sub-title>
            </v-list-item-content>

            <v-list-item-icon>
                <v-btn icon @click="UpdateStatus(chat.id)">
                  <v-icon :color="chat.ver ? 'deep-purple accent-4' : 'grey'">
                    mdi-message-outline
                  </v-icon>
                </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <!-- <v-chip
               class="ma-2"
               color="default"
               label
               text-color="black"
               >
                {{ chat.status }}
              </v-chip> -->

        <v-divider></v-divider>
        <v-card-actions>
                <v-spacer></v-spacer>  
                <v-btn text @click="getNotify">
                  Actualizar
                </v-btn>
               

                <router-link to="/eventos"> 
                  <v-btn text>Ir Notificaciones</v-btn>                  
                </router-link>
                <!-- <v-btn color="primary" text @click="menu = false">Guardar</v-btn> -->
        </v-card-actions>

        <!-- <v-list subheader> -->
          <!-- <v-subheader>Previous chats</v-subheader> -->

          <!-- <v-list-item
            v-for="chat in previous"
            :key="chat.title"
          >
            <v-list-item-avatar>
              <v-img
                :alt="`${chat.title} avatar`"
                :src="chat.avatar"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="chat.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        <!-- </v-list> -->
      </v-card>
    </template>
    
  </v-menu>
    <!--
      Alert 
    type="success"
    type="info"
    type="warning"
    type="error" 
    -->
  <template>
  <div>
    <!-- close-text="Close Alert" -->
    <v-alert
      :value="alert"
      transition="scale-transition"
      color="deep-orange"
      icon="warning"
      type="warning"
      prominent
      shaped 
      >
      <!-- <h3 class="text-h5">
        Lorem Ipsum
      </h3> -->
      <v-row
        no-gutters
        align="center"
      >
        <v-col class="grow">
          {{ newnotify.name }} 
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="shrink">
          <v-btn
            @click="UpdateStatus(newnotify.id)"
            color="deep-dark"          
          >
          Leido
        </v-btn>
      </v-col>
    </v-row>
    </v-alert>
  </div>
</template>
    
 </div>
</template>


<script>
// {{ row.fecha_inicio }} - {{ row.fecha_fin }} <br>
//           
//            <br><br>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import baseApi from '@/services/baseApi';
// import icheckApi from '@/services/icheckApi';
// import router from '@/router'


export default{
  name: "notify",  
  computed:{
        ...mapState(['notifications'])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    listenForNotifications() {
      const channel = this.$pusher.subscribe('notifications');
      channel.bind('App\\Events\\NotificationCreated', (data) => {
        this.notifications.push(data.notification);
        this.newnotify=data.notification;
        this.alert=true;
      });
    },
    // UpdateStatusKardex(id){
    //   // alert('entro'+id); id es de branch_id
    //   console.log(id);
      // this.alert=false;
    // },
    async UpdateStatus(id){
      // alert("Estoy aqui"+id)
      try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})        
            baseApi().post(`/eventos/update/${id}`,JSON.stringify({'status':'Finalizado'})).then((response)=>{
                if(!response.data.error){                            
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
              });                                                                                                    
            // console.log("VENTA: "+response.data.message);
      }catch(error){
        // console.log('Notify Update '+error);
      }  finally{
            this.hiddenLoading()
            this.alert=false;
            // this.$swal("Genial!", "Se actualizó la información", "success");
      }
    },
    async getNotify(){   
      //alert('tips');       
      try{
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadNotifications');     
      }catch(error){
        console.log('Notify '+error);
      }  finally{
        this.hiddenLoading()
      } 
    }    
  },
  data(){
    return{
        revents:null,
        alert:false,
        newnotify:[]
    }
  },
  created(){
    this.getNotify()
    this.listenForNotifications();
  } 
}
</script>
<template>
<div id="tooltop" >
    <v-toolbar class="primary"> 
      <v-app-bar        
        elevate-on-scroll
        class="primary"
      >
      <!-- absolute -->
      <!-- scroll-target="#scrolling-techniques-7" -->
        
        <v-app-bar-nav-icon @click="drawer = !drawer">      
          <v-icon>menu</v-icon>
        </v-app-bar-nav-icon>       

        <v-toolbar-title dark>iCheck</v-toolbar-title>
      </v-app-bar>
      <!-- para que se vaya a la derecha -->
      
      <v-spacer></v-spacer>      
      <!-- <SearchProducts /> -->
      <!-- <FieldSearch2 />      -->
      <v-spacer></v-spacer>      
      
      <!-- <v-btn>
        <Indicador />
      </v-btn>       -->

      <!-- Car -->
      <Car />   
      <!-- FIN Car -->


      <!-- Eventos -->
      <Notify /> 
      <!-- FIN Eventos -->

                    
      <!-- Perfil usuario -->       
      <!-- <v-menu v-if="currentUser.name" 
        v-model="menu2"
        :close-on-content-click="false"
        offset-y 
        transition="scale-transition"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>widgets</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <router-link to="/settings">
                <v-btn >              
                  <v-icon>settings</v-icon>Perfil
                </v-btn>
              </router-link>
              
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn @click="logoutUser">              
                <v-icon>login</v-icon>Cerrar Sesión
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>      
      <v-btn icon dark to="/login" v-else>
        <v-icon>lock_open</v-icon>
      </v-btn> -->
              
      

      <!-- settings extra -->
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>     

            <v-card>
              <v-list>
                <v-list-item v-if="currentUser.name">
                  <v-list-item-avatar v-if='currentUser.profile_avatar != null'>
                    <v-img
                      v-bind:lazy-src="currentUser.profile_avatar"                             
                      v-bind:src="currentUser.profile_avatar"                      
                      max-height="150"
                      max-width="150"
                    ></v-img>                 
                  </v-list-item-avatar>     
                  <v-list-item-avatar v-else>                    
                    <v-img
                      v-bind:lazy-src="currentUser.branch_filename"                      
                      max-height="150"
                      max-width="150"
                      v-bind:src="currentUser.branch_filename"                      
                    ></v-img>                    
                    <!-- 
                      lazy-src="/img/avatar/avatar-1.png"
                      src="/img/avatar/avatar-1.png" 
                    -->
                  </v-list-item-avatar>     

                  <v-list-item-content>
                    <v-list-item-title>{{ currentUser.name }} </v-list-item-title>
                    <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
                  </v-list-item-content>      

                  <v-list-item-action>
                    <v-btn
                      :class="fav ? 'red--text' : ''"
                      icon
                      @click="fav = !fav"
                    >
                      <v-icon>mdi-heart</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item v-else>
                      <span style="color:red;"> Necesita Loguearse</span>
                </v-list-item>
              </v-list>     

              <v-divider></v-divider> 
              <v-list>
                <v-list-item>
                  <v-list-item-title>
                    <router-link to="/settings">
                      <v-btn text>              
                        <v-icon>settings</v-icon>Perfil Settings
                      </v-btn>
                    </router-link>
                    
                  </v-list-item-title>
                </v-list-item>
              </v-list>    

              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch v-model="message" color="purple"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Habilitar Notificaciones</v-list-item-title>
                </v-list-item>      

                <v-list-item>
                  <v-list-item-action>
                    <v-switch v-model="hints" color="purple"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Habilitar Mensajes</v-list-item-title>
                </v-list-item>
                <v-spacer></v-spacer>     


              </v-list>   
              <v-divider></v-divider>     


              <v-card-actions>
                <v-spacer></v-spacer>  
                <v-btn @click="logoutUser" class="orange" >              
                      <v-icon>login</v-icon>Cerrar Sesión
                </v-btn>

                <!-- <v-btn text @click="menu = false">Cancelar</v-btn> -->
                <!-- <v-btn color="primary" text @click="menu = false">Guardar</v-btn> -->
              </v-card-actions>
            </v-card>
      </v-menu>
      <!-- FIN Setting extra -->
    </v-toolbar>

  
   <v-navigation-drawer 
      v-model="drawer"         
      fixed
      temporary      
      src="/img/bg/bg1.jpg"
      >
      <!-- permanent 
      expand-on-hover -->
        <v-list-item>
          <v-badge            
            bottom
            color="green accent-4"
            dot
            offset-x="25"
            offset-y="20"
          >
          <v-list-item-avatar v-if='currentUser.profile_avatar != null'>
                    <v-img
                      v-bind:lazy-src="currentUser.profile_avatar"                             
                      v-bind:src="currentUser.profile_avatar"                      
                      max-height="150"
                      max-width="150"
                    ></v-img>                 
          </v-list-item-avatar>     
          <v-list-item-avatar v-else>                    
                    <v-img
                      v-bind:lazy-src="currentUser.branch_filename"                      
                      max-height="150"
                      max-width="150"
                      v-bind:src="currentUser.branch_filename"                      
                    ></v-img>    
          </v-list-item-avatar>
        </v-badge>
                                        
           <v-list-item-content>
             <v-list-item-title>  {{currentUser.name}}</v-list-item-title>
             <v-list-item-subtitle>  {{currentUser.email}}</v-list-item-subtitle>
           </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >         
          <v-list-item-icon>            
              <v-icon>{{ item.icon }}</v-icon>            
          </v-list-item-icon>

          <v-list-item-content>
            <router-link :to="item.url" style="color:white">
              <v-list-item-title >{{ item.title }}</v-list-item-title>
            </router-link>  
          </v-list-item-content>               
        </v-list-item>

        <v-list>
          <v-list-group
            prepend-icon="border_all"
            value="true"
          >
            <template v-slot:activator>
              <v-list-item-title>Catalogo</v-list-item-title>
            </template>   

              <v-list-item
                v-for="(crud, i) in icatalogo"
                :key="i"
                
              >
                <v-list-item-title>
                  <router-link :to="crud.url" style="color:white">
                    {{crud.title}}
                  </router-link>
                </v-list-item-title>
                <v-list-item-action>
                  <v-icon v-text="crud.icon"></v-icon>
                </v-list-item-action>
              </v-list-item>
          </v-list-group>
        </v-list>   
                                                                                                                                  
        <v-list-item
          v-for="imore in imore"
          :key="imore.title"
          link
        >         
          <v-list-item-icon>            
              <v-icon>{{ imore.icon }}</v-icon>            
          </v-list-item-icon>
          <v-list-item-content>
            <router-link :to="imore.url" style="color:white">
              <v-list-item-title>{{ imore.title }}</v-list-item-title>
            </router-link>  
          </v-list-item-content>               
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

  </div>
</template>

<script>
// import Indicador from '@/components/Indicador'
import Car from '@/components/Car'
import Notify from '@/components/Notify'
// import SearchProducts from '@/components/SearchProducts'
// import FieldSearch2 from '@/components/FieldSearch2'
import{ mapState } from "vuex";

export default {
  name: 'tooltop',  
  components: {
    // Indicador,
    Car,
    Notify,
    // FieldSearch2
    // SearchProducts,
  },  
  computed:{
    ...mapState(['currentUser'])
  },
  methods:{
    logoutUser(){
      this.$store.dispatch("logoutUser");
      this.$router.push({name: 'Login'});
      this.$swal("Bye!",'Regresa Pronto '+this.currentUser.name, "success");
    }
  },
  created(){
    // console.log('ToolTop: '+this.currentUser.profile_avatar)
  },
  data(){
    return{
      menu:"",
      menu2:"",
      drawer:null,
      fab:null,      
      hints:"", //Habilitar Notificaciones
      message:"", //Habilitar Mensajes
      fav:"", //Habilitar Favorite
      items: [
          { title: 'Dashboard', icon: 'mdi-view-dashboard', url:'/' },
          { title: 'Cotización', icon: 'adjust',url:'/quotes' },
          { title: 'Precios', icon: 'keyboard_arrow_right',url:'/precios' },
          { title: 'Ventas', icon: 'add_shopping_cart',url:'/ventas' },
          { title: 'Gastos', icon: 'attach_money',url:'/gastos' },
          { title: 'Compras', icon: 'payment',url:'/compras' },
          /*{ title: 'Reporte', icon: 'list',url:'/stock' },*/
      ],
      icatalogo: [
        { title: 'Clientes', icon: 'adjust',url:'/clientes' },
        { title: 'Eventos', icon: 'mdi-image',url:'/eventos' },
        // { title: 'Productos', icon: 'mdi-help-box',url:'/productos' },
        // { title: 'Tips', icon: 'keyboard_arrow_right',url:'/tips' },
        { title: 'Mensajes', icon: 'mdi-image',url:'/mensajes' },
        { title: 'Proveedores', icon: 'mdi-account-supervisor-circle',url:'/proveedores' },
        { title: 'Empleados', icon: 'adjust',url:'/empleados' },
        { title: 'Usuarios', icon: 'people',url:'/usuarios' },        
        // { title: 'Camapañas', icon: 'mdi-image',url:'/campaigns' },
      ],
      imore: [
        //{ title: 'Perfil', icon: 'settings',url:'/perfil' },        
        // { title: 'Logs', icon: 'mdi-track_changes',url:'/logs' },
        //{ title: 'Documentación', icon: 'import_contacts',url:'/documentacion' },
        //{ title: 'Acerca de...', icon: 'help_outline',url:'/acerca' },
      ],
      admins: [
        ['Management', 'people_outline'],
        ['Settings', 'settings'],
      ],
      cruds: [
        ['Create', 'add'],
        ['Read', 'insert_drive_file'],
        ['Update', 'update'],
        ['Delete', 'delete'],
      ],      
      right: null,
      expandOnHover: false,
      miniVariant: true,
    };
  }
}
</script>

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Pusher from 'pusher-js';
import VueAnalytics from 'vue-analytics';






// If you don't need the styles, do not connect
//import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2, options);
//Vue.use(VueSweetalert2);

// import Dayjs from 'dayjs';
// Vue.use(Dayjs);
// Vue.use(Dayjs, Dayjs)

// Vue.provide('dayJS', Dayjs) // provide dayJS

// Vue.config.globalProperties.$dayjs = Dayjs

import{ ComboBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";
Vue.use(ComboBoxPlugin);

// import draggable from 'vue-vuedraggable';
// Vue.use(draggable)
// Vue.directive('sortable', {
//   inserted: function (el, binding) {
//     new Sortable(el, binding.value || {})
//   }
// })


import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);

/*
import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
  symbol: '$', // El símbolo, por ejemplo €
  thousandsSeparator: ',', // Separador de miles
  fractionCount: 2, // ¿Cuántos decimales mostrar?
  fractionSeparator: '.', // Separador de decimales
  symbolPosition: 'front', // Posición del símbolo. Puede ser al inicio ('front') o al final ('') es decir, si queremos que sea al final, en lugar de front ponemos una cadena vacía ''
  symbolSpacing: true // Indica si debe poner un espacio entre el símbolo y la cantidad
})
*/

import VueCompositionApi from "@vue/composition-api";
import vuetify from '@/plugins/vuetify';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios)

// import jsPDF from 'jspdf';
//import * as jsPDF  from "jspdf"
//import { jsPDF } from "jspdf";

// El paquete esta instalado pero no lo encuetra
// import VueAnalytics from 'vue-analytics';

// const VueAnalytics = require('vue-analytics').default

Vue.use(VueAnalytics, {
   id: 'UA-100700920-9',
   router
});


const pusher = new Pusher('c7fd99b5022d52b98e59', {
  cluster: 'mt1',
  encrypted: true
});

Vue.prototype.$pusher = pusher;



Vue.config.productionTip = false
Vue.use(VueCompositionApi);

new Vue({
  router,
  store,
  // jsPDF,
  vuetify,
  render: h => h(App)
}).$mount('#app')

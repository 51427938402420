import Vue from 'vue'
import Vuex from 'vuex'
//Llamamos a la api
// import Api from '@/services/api';
import ApiPW from '@/services/apiPW';
import baseApi from '@/services/baseApi';
import icheckApi from '@/services/icheckApi';
import auth from './modules/auth.js';
// import router from '@/router'
// import store from '@/store';


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  },
  state: {
    breadcrumbs:{
        page:"Dashboard",
        title:"Dashboard",
        subtitle:"Subtitle",
        description:"Descripcion de la Página"        
    },
    filter:{
      query:'',
      status:'Privado', //Descontinuado,Privado,Público
      type_article:"Ninguno", //Ninguno,Productos,Servicios
      stock:"ninguno" //ninguno,stock,stocksyscom,etc
    },
    fields:[
      {nombre:"Cantidad",qty:1},
      {nombre:"Cantidad",cantidad:1},
      {nombre:"Precio",cantidad:3},
      {nombre:"Stock",cantidad:7 },
      {nombre:"Metrica",cantidad:7 },
    ],
    frutas:[
      {nombre:"Manzana",cantidad:1},
      {nombre:"Pera",cantidad:3},
      {nombre:"Cantidad",cantidad:7 },
    ],
    loading:{
      titulo:"",
      estado:false,
      color:""
    },    
    users:[],
    currentUser:{},
    setting:{},
    categoryExpensives:{},
    brands:[],
    comeback:[],
    branches:[],
    measurements:[],
    familycategories:[],
    subfamilies:[],
    compaigns:[],
    customers:[],
    currencies:[],
    kpis:[],
    suppliers:[],
    employees:[],
    expensives:[],
    payments:[],
    plugins:[],
    products:[],
    purchases:[],
    priceslist:[],
    pwprice:[],
    pwproducts:[],
    pwproviders:[],
    projects:[],
    profile:[],
    salesorders:[],
    quotes:[],
    invoices:[],
    saldo:[],
    notifications:[],
    car:[],
    tips:[],
    videos:[],
    events:[]
  },
  mutations: {
    changeBreadcrumbs(state,valor){
      state.breadcrumbs.page=valor.page;
      state.breadcrumbs.title=valor.title;
      state.breadcrumbs.subtitle=valor.subtitle;
      state.breadcrumbs.description=valor.description;
    },
    aumentar(state,index){
      // state.frutas[index].cantidad ++
      // state.fields[index].cantidad ++
      state.fields[index].qty ++
    },
    disminuir(state,index){
      // state.frutas[index].cantidad ++
      // state.fields[index].cantidad --
      state.fields[index].qty --
    },
    reiniciar(state){
      state.frutas.forEach(elemento=>{
        elemento.cantidad=0
      })
    },
    showLoading(state,payload){
      state.loading.titulo=payload.titulo
      state.loading.estado=true
      state.loading.color=payload.color
    },
    hiddenLoading(state){
      state.loading.estado=false
    },    
    /* Buscador de Precios 
    ** Emmanuel Carreon
    ** 06 de Dic de 2021
    */
    SET_QUERY(state,query){
      state.filter.query=query;
    },
    SET_STATUS(state,status){
      // state.filter.status =! state.filter.status; //Cuando sea false será true y vicebersa
      state.filter.status =status;
    },
    SET_ARTICLE(state,type_article){
      // state.filter.ver =! state.filter.ver; //Cuando sea false será true y vicebersa
      state.filter.type_article =type_article;
    },
    SET_STOCK(state,stock){
      // state.filter.ver =! state.filter.ver; //Cuando sea false será true y vicebersa
      state.filter.stock=stock;
    },
    /* FIN Buscador de Precios */
    SET_USERS(state,users){
      state.users=users;
    },
    LOGOUT_USER(state){
      state.currentUser={};
      window.localStorage.currentUser=JSON.stringify({});
    },
    SET_CURRENT_USER(state,user){
      state.currentUser=user;
      window.localStorage.currentUser=JSON.stringify(user);
    },    
    SET_SETTING(state,setting){
      state.setting=setting;
    },
    SET_COMPAIGNS(state,compaigns){
      state.compaigns=compaigns;
    },
    SET_CATEGORY_EXPENSIVES(state,categoryExpensives){
      state.categoryExpensives=categoryExpensives;
    },
    SET_BRANDS(state,brands){
      state.brands=brands;
    },
    SET_BRANCHES(state,branches){
      state.branches=branches;
    },
    SET_MEASUREMENTS(state,measurements){
      state.measurements=measurements;
    },
    SET_FAMILYCATEGORIES(state,familycategories){
      state.familycategories=familycategories;
    },
    SET_SUBFAMILIES(state,subfamilies){
      state.subfamilies=subfamilies;
    },
    SET_COMEBACK(state,comeback){
      state.comeback=comeback;
    },
    SET_CURRENCIES(state,currencies){
      state.currencies=currencies;
    },    
    SET_CUSTOMERS(state,customers){
      state.customers=customers;
    },    
    SET_EXPENSIVES(state,expensives){
      state.expensives=expensives;
    },
    SET_EMPLOYEES(state,employees){
      state.employees=employees;
    },
    SET_KPIS(state,kpis){
      state.kpis=kpis;      
    },
    SET_SUPPLIERS(state,suppliers){
      state.suppliers=suppliers;
    },
    SET_SALESORDERS(state,salesorders){
      state.salesorders=salesorders;
    },
    SET_PAYMENTS(state,payments){
      state.payments=payments;
    },
    SET_PLUGINS(state,plugins){
      state.plugins=plugins;
    },
    SET_PRODUCTS(state,products){
      state.products=products;
    },
    SET_PROJECTS(state,projects){
      state.projects=projects;
    },
    SET_PROFILE(state,profile){
      state.profile=profile;
    },
    SET_PRICESLIST(state,priceslist){
      state.priceslist=priceslist;
    },
    SET_PWPRICE(state,pwprice){
      state.pwprice=pwprice;
    },
    SET_PURCHASES(state,purchases){
      state.purchases=purchases;
    },    
    SET_PWPRODUCTS(state,pwproducts){
      state.pwproducts=pwproducts;
    },
    SET_PWPROVIDERS(state,pwproviders){
      state.pwproviders=pwproviders;
    },
    SET_QUOTES(state,quotes){
      state.quotes=quotes;
    },
    SET_INVOICES(state,invoices){
      state.invoices=invoices;
    },
    SET_EVENTS(state,events){
      state.events=events;
    },
    SET_SALDO(state,saldo){
      state.saldo=saldo;
    },
    SET_TIPS(state,tips){
      state.tips=tips;
    },
    SET_VIDEOS(state,videos){
      state.videos=videos;
    },
    SET_NOTIFICATIONS(state,notifications){
      state.notifications=notifications;
    },
    SET_CAR(state,car){
      state.car=car;
    },
  },
  getters:{ //Modificar el estado, pero no se modifica el original sino la copia
    filteredPrices (state){
      //console.log('Status Filter Price: '+state.filter.status);
      let priceslist = state.priceslist.filter(price => price.status === state.filter.status);
      // let priceslist = state.priceslist.filter(price => price.status === "Público"); //status Público
      if (state.filter.query.length>=3) { //Cuando se escriba mas de dos carácteres entonces que busque en el array
        const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
        // let arr = [null, undefined, ''];
        // let isNotNull = value => value != null;
        return priceslist.filter(
          (price) =>
            //price.id.toString().includes(state.filter.query.toString)  ||
            price.description.split('').map( letra => acentos[letra] || letra).join('').toString().toLowerCase().includes(state.filter.query.toLowerCase()) ||
            price.sku.toLowerCase().includes(state.filter.query.toLowerCase())  ||
            price.id.toString().toLowerCase().includes(state.filter.query.toLowerCase())  ||
            // price.modelo.toLowerCase().includes(state.filter.query.toLowerCase()) || //Error cuando es NULL
            (price.modelo && price.modelo.toLowerCase().indexOf(state.filter.query.toLowerCase()) > -1) ||
            (price.meli_id && price.meli_id.toLowerCase().indexOf(state.filter.query.toLowerCase()) > -1) //se resuelve los valores null o ''
            // (price.ctonline_id && price.ctonline_id.toLowerCase().indexOf(state.filter.query.toLowerCase()) > -1) //se resuelve los valores null o ''
            // (price.syscom_id && price.syscom_id.toLowerCase().indexOf(state.filter.query.toLowerCase()) > -1) //se resuelve los valores null o ''
        ); 
        //status="Público"
        //status="Descontinuado"        
      }
      return "";
    },
    filteredCustomers (state){
      let customers = state.customers.filter(customer => customer.status === state.filter.status); //Publicados
      // let priceslist = state.priceslist.filter(price => price.status === "Público"); //status Público
      if (state.filter.query.length>=4) { //Cuando se escriba mas de dos carácteres entonces que busque en el array
        const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
        return customers.filter(
          (customer) =>
            //price.id.toString().includes(state.filter.query.toString)  ||
            customer.description.split('').map( letra => acentos[letra] || letra).join('').toString().toLowerCase().includes(state.filter.query.toLowerCase()) ||
            customer.sku.toLowerCase().includes(state.filter.query.toLowerCase())  ||
            customer.modelo.toLowerCase().includes(state.filter.query.toLowerCase())
        ); 
        //status="Público"
        //status="Descontinuado"        
      }
      return "";
    },
    filteredStock(state) {
      let statusstock = state.filter.stock; //Default es all
      // console.log('Status Filter Stock: '+state.filter.stock);
      // console.log('Status Filter Stock: '+statusstock);
      if (statusstock=='ssyscom'){
      // if (state.priceslist) {
        return state.priceslist.filter((priceslist) => {
          return priceslist.stock_syscom > 0
        })
      }else if (statusstock=='camino') {
        return state.priceslist.filter((priceslist) => {
          return priceslist.camino > 0
        })
      }else if (statusstock=='minstock') {
        return state.priceslist.filter((priceslist) => {
          return priceslist.min_stock > 0
        })
      }else if (statusstock=='maxstock') {
        return state.priceslist.filter((priceslist) => {
          return priceslist.max_stock > 0
        })
      }else if (statusstock=='stock') {
        return state.priceslist.filter((priceslist) => {
          return priceslist.stock > 0
        })
      }else if (statusstock=='sctonline') {
        return state.priceslist.filter((priceslist) => {
          return priceslist.stock_ctonline > 0
        })
      }else if (statusstock=='scva') {
        return state.priceslist.filter((priceslist) => {
          return priceslist.stock_cva > 0
        })
      }

      // if (state.priceslist) {
      //   return state.priceslist.filter((priceslist) => {
      //     return priceslist.stock
      //   })
      // }
      return "";
  
    },
    GET_CURRENT_USER:state=>state.currentUser
  },
  actions: {
    async loadSaldo({commit},attribute){  
      //Poner el valor del id adecuado conforme logueo         
      // let user=JSON.parse(window.localStorage.currentUser);
      let response=Array;
      if (attribute.date_start=="" || attribute.date_start=="undefined") {
        response = await icheckApi().get(`/saldos/getall?word=${attribute.sword}`);
      }else{
        response = await icheckApi().get(`/saldos/getall?word=${attribute.sword}&valor=${attribute.date_start}|${attribute.date_end}`);        
      }
      
      let saldo=response.data;
      commit('SET_SALDO',saldo);        
    },   
    async loadUsers({commit},sword){
      // let user=JSON.parse(window.localStorage.currentUser);
      // let response = await baseApi().get(`/usuarios/getall?branch_id=${user.branch_id}&sword=${sword}`);
      let response = await baseApi().get(`/usuarios/getall?sword=${sword}`);
      let users=response.data;
      commit('SET_USERS',users);
      //v.array pones el nombre del array
      //commit('SET_USERS',users).map(u=>u.array); 
    },
    async loadCurrentUser({commit}){
      let user=JSON.parse(window.localStorage.currentUser);
      commit('SET_CURRENT_USER',user);
    },
    async loginUser({commit},loginInfo){
      try{
        let response = await baseApi().post(`/login`,loginInfo);
        if(response.data.error==true){                            
          // let user=response.data;
          let user=response.data;
          // commit('SET_CURRENT_USER',user);
          commit('SET_AUTH',false);

          return user;
        }else{

          // let user=response.data;
          let user=response.data.data.user;
          commit('SET_CURRENT_USER',user);
          commit('SET_AUTH_USER',user);
          commit('SET_TOKEN',response.data.data.token);
          commit('SET_AUTH',true);
          //Este puede funcionar para cambiar de usuario con un clic pero
          //se tendria que poner todos los link con branch_id
          //window.localStorage.currentUser=JSON.stringify(user);
          localStorage.setItem('token',response.data.data.token.value);                     
          return response.data; 
        }     
        
      }catch{
        //return response.data;
        // return {error:true,message:"Email y/o password incorrectos, Intentelo nuevamente"}
        return {error:true,message:"Email y/o password incorrectos, Intentelo nuevamente"}
      }           
    },        
    async sloginUser({commit},loginInfo){      
      //Esta funcion es para loguearnos sin mandar peticion a la api 
      // store.dispatch('SET_TOKEN',response.data.data.token);   
      //Ya no funciona por el token
      commit('SET_CURRENT_USER',loginInfo);
      return loginInfo;           

      // try{
      //   let response = await baseApi().post(`/login`,{"email":loginInfo.email,"password":loginInfo.password});
      //   // let user=response.data;
      //   let user=response.data.data.user;
      //   commit('SET_CURRENT_USER',user);
      //   store.dispatch('SET_TOKEN',response.data.data.token);   
      //   return user;
      // }catch{
      //   //return response.data;
      //   return {error:"Email y/o password incorrectos, Intentelo nuevamente"}
      // }         
    },        
    async logoutUser({commit}){

      try{
        let response = await baseApi().post(`/logout`);
        console.log('Salir '+response);
        //if(response.data.error==true){                            
          // let user=response.data;
          //let user=response.data;
          // commit('SET_CURRENT_USER',user);
          //commit('SET_AUTH',false);

          //return user;
        //}else{

          // let user=response.data;
      }catch{
        //Salir
      }    

      commit('LOGOUT_USER');      
    },
    async registerUser({commit},registrationInfo){
      try{
        let response = await baseApi().post(`/usuarios`,registrationInfo);
        let user=response.data;
        commit('SET_CURRENT_USER',user);
        return user;
      }catch{
        //return response.data;
        return {error:"Algo salio mal, Intentelo nuevamente"}
      }           
    },
    async forgetUser(forgetInfo){
      try{
        //let response = await baseApi().post(`/usuario/forget`,forgetInfo);
        //let user=response.data;
        //alert(forgetInfo.email);
        let email=forgetInfo; //no found
        //commit('SET_EMAIL_USER',email);
        return email;
      }catch{
        //return response.data;
        return {error:"Email y/o password incorrectos, Intentelo nuevamente"}
      }           
    },     
    async loadSetting({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("Setting: "+attribute.sword);
      // if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/settinguser/getall`);
      // }else{
        // response = await baseApi().get(`/settinguser/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
      // }      
      let setting=response.data;
      commit('SET_SETTING',setting);      
    },   
    async loadProfile({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("Profile: "+attribute.sword);
      // if (attribute.sword=="All"){ //Funciona para los combo box
        response = await icheckApi().get(`/perfil/getid`);
      // }else{
        // response = await baseApi().get(`/settinguser/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
      // }      
      let row=response.data;
      commit('SET_PROFILE',row);      
    },      
    async loadBrands({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("Marcas: "+attribute.sword);
      if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/marcas/getall`);
      }else{
        response = await baseApi().get(`/marcas/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
      }      
      let brands=response.data;
      commit('SET_BRANDS',brands);      
    },  
    async loadBranchOne({commit}){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("Sucursales: ");
      response = await baseApi().get(`/sucursales/2727`);
      let rows=response.data;
      commit('SET_BRANCHES',rows);      
    },   
    async loadProjects({commit},attribute){
      let response="";
      console.log("Proyecto: "+attribute.sword);
      attribute.sword=attribute.sword.toString().toLowerCase();

      if (attribute.sword=="all"){ //Funciona para los combo box
        response = await baseApi().get(`/proyectos/getall`);
      }else{
        response = await baseApi().get(`/proyectos/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
      }      
      let rows=response.data;
      commit('SET_PROJECTS',rows);      
    },  
    async loadMeasurements({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("Medidas: "+attribute.sword);
      if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/umedidas/getall`);
      }else{
        response = await baseApi().get(`/umedidas/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
      }      
      let measurements=response.data;
      commit('SET_MEASUREMENTS',measurements);      
    }, 
    async loadFamilyCategories({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("Familias: "+attribute.sword);
      if (attribute.sword=="All"){ //Funciona para los combo box
        response = await icheckApi().get(`/familiacategorias/getall`);
      }else{
        response = await icheckApi().get(`/familiacategorias/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
      }      
      let familycategories=response.data;
      commit('SET_FAMILYCATEGORIES',familycategories);      
    },
    async loadSubFamilies({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("SubFamilias: "+attribute.sword);
      if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/subfamilias/getall`);
      }else{
        response = await baseApi().get(`/subfamilias/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
      }      
      let subfamilies=response.data;
      commit('SET_SUBFAMILIES',subfamilies);      
    },  
    async loadCustomers({commit},attribute){
      //let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      if (attribute.sword=="All"){ //Funciona para los combo box
        //response = await baseApi().get(`/clientes/getall?branch_id=${user.branch_id}`);
        response = await baseApi().get(`/clientes/getall`);
      }else{
        response = await baseApi().get(`/clientes/getall?limit=${attribute.limit}&word=${attribute.sword}`);
      }
      
      let customers=response.data;
      commit('SET_CUSTOMERS',customers);      
    },   
    async loadCurrencies({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      // console
      let response="";
      if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/moneda/getall`);
      }else{
        response = await baseApi().get(`/moneda/getall`);
      }
      
      let currencies=response.data;
      commit('SET_CURRENCIES',currencies);      
    },   
    async loadKpis({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let qresponse="";
      let sresponse="";
      let presponse="";
      let eresponse="";
      //console.log("Marcas: "+attribute.sword);
      // let response=Array;
      if (attribute.date_start=="" || attribute.date_start=="undefined") {      
        qresponse = await baseApi().get(`/cotizaciones/gettotal`);
        sresponse = await baseApi().get(`/notaremision/gettotal`);
        presponse = await baseApi().get(`/compras/gettotal`);
        eresponse = await baseApi().get(`/gastos/gettotal`);
      }else{
        qresponse = await baseApi().get(`/cotizaciones/gettotal?valor=${attribute.date_start}|${attribute.date_end}`);
        sresponse = await baseApi().get(`/notaremision/gettotal?valor=${attribute.date_start}|${attribute.date_end}`);
        presponse = await baseApi().get(`/compras/gettotal?valor=${attribute.date_start}|${attribute.date_end}`);
        eresponse = await baseApi().get(`/gastos/gettotal?valor=${attribute.date_start}|${attribute.date_end}`);                
      }      
      //this.showLoading({titulo:"Accediendo información",color:"primary"})
      let kpis={
         qvalue:qresponse.data,
         svalue:sresponse.data,
         pvalue:presponse.data,
         evalue:eresponse.data
        };

      commit('SET_KPIS',kpis);      
    },   
    async loadSuppliers({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("Proveedores: "+attribute.sword);
      if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/proveedores/getall`);
        // response = await baseApi().get(`/proveedores/getall?branch_id=1`);
      }else{
        response = await baseApi().get(`/proveedores/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
      }      
      let suppliers=response.data;
      commit('SET_SUPPLIERS',suppliers);      
    },   
    async loadEmployees({commit},attribute){    
      let response="";
      console.log("Empleados: "+attribute.sword);
      attribute.sword=attribute.sword.toString().toLowerCase();
      if (attribute.sword=="all"){ //Funciona para los combo box
        response = await baseApi().get(`/empleados/getall`);
      }else{
        response = await baseApi().get(`/empleados/getall?word=${attribute.sword}`);
        // response = await baseApi().get(`/proveedores/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
      }      

      let rows=response.data;
      commit('SET_EMPLOYEES',rows);      
    },   
    async loadExpensives({commit},sword){      
      // let user=JSON.parse(window.localStorage.currentUser);  
      let response="";
      if (sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/gastos/getall`);
      }else{
        response = await baseApi().get(`/gastos/getall?word=${sword}`);
      }

      let expensives=response.data;
      commit('SET_EXPENSIVES',expensives);      
    },
    async loadExpensives2({commit},attribute){      
      // let user=JSON.parse(window.localStorage.currentUser);      
      let daterange='';
      if (attribute.date_start=="" || attribute.date_start=="undefined") {
        daterange='';
      }else{
        daterange=attribute.date_start+"|"+attribute.date_end;        
      }     

      let response = await baseApi().get(`/gastos/getall?valor=${daterange}&word=${attribute.sword}`);
      let expensives=response.data;
      commit('SET_EXPENSIVES',expensives);      
    },   
    async loadCompaigns({commit},attribute){
      let user=JSON.parse(window.localStorage.currentUser);  
      let response="";
      if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/campaigns/getall?branch_id=${user.branch_id}`);
      }else{
        response = await baseApi().get(`/campaigns/getall?branch_id=${user.branch_id}&sword=${attribute.sword}`);
      }      
      
      let compaigns=response.data;
      commit('SET_COMPAIGNS',compaigns);        
    },   
    async loadCategoryExpensives({commit},sword){      
      let response="";
      if (sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/categoriagastos/getall`);
      }else{
        response = await baseApi().get(`/categoriagastos/getall?sword=${sword}`);
      }

      let categoryExpensives=response.data;
      commit('SET_CATEGORY_EXPENSIVES',categoryExpensives);      
    },  
    async loadComeback({commit},attribute){
      let response="";
      let daterange='';
      if (attribute.date_start=="" || attribute.date_start=="undefined") {
        daterange='';
      }else{
        daterange=attribute.date_start+"|"+attribute.date_end;        
      }   

      if (attribute.pricelistid){
        //NO YET FOUND
        // response = await baseApi().get(`/compras/getall?valor=${daterange}&word=${attribute.sword}&tipo=${attribute.tipo}&pricelistid=${attribute.pricelistid}`);
      }else if (attribute.projectid){
        response = await baseApi().get(`/devoluciones/getall?tipo=${attribute.tipo}&valor=${daterange}&word=${attribute.sword}&projectid=${attribute.projectid}`);
      }else if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/devoluciones/getall?tipo=return&valor=${daterange}&word=${attribute.sword}`);
      }else{
        response = await baseApi().get(`/devoluciones/getall?tipo=return&word=${attribute.sword}`);
      }
      
      let rows=response.data;
      commit('SET_COMEBACK',rows);        
    }, 
    async loadProducts({commit},sword){      
      let user=JSON.parse(window.localStorage.currentUser);  
      let response="";
      if (sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/productos/getall?branch_id=${user.branch_id}`);
      }else{
        response = await baseApi().get(`/productos/getall?branch_id=${user.branch_id}&sword=${sword}`);
      }

      let products=response.data;
      commit('SET_PRODUCTS',products);      
    },
    async loadPricelists({commit},sword){
      // let user=JSON.parse(window.localStorage.currentUser);  
      let response="";
      if (sword=="All"){ //Funciona para los combo box
          response = await icheckApi().get(`/precios/getall`);
      }else{
        response = await icheckApi().get(`/precios/getall?limit=27&word=${sword}`);
      }
      
      let priceslist=response.data;
      commit('SET_PRICESLIST',priceslist);  
    },  
    async loadPricelists2({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);  
      let response="";
      // if (attribute.sword=="All"){
        if (attribute.status=="All") //Funciona para los combo box
          response = await baseApi().get(`/precios/getall?status=${attribute.status}`);
      //   response = await baseApi().get(`/precios/getall`);        
      // }else{
      //   response = await baseApi().get(`/precios/getall?limit=27&word=${attribute.sword}`);
      // }
      
      //cuando descomento todo hace 2 consultas por el componente fieldSearch2
      let priceslist=response.data;
      commit('SET_PRICESLIST',priceslist);  
    },   
    async loadPWPrice({commit},sword){
      let response = await ApiPW().get(`/products/index?word=${sword}`);
      let pwprice=response.data;
      commit('SET_PWPRICE',pwprice);  
    },   
    async loadPWProduct({commit},sword){
      let response = await ApiPW().get(`/products/index?word=${sword}`);
      let pwproducts=response.data;
      commit('SET_PWPRODUCTS',pwproducts);  
    },  
    // async loadPWProviders({commit},sword){
    async loadPWProviders({commit}){
      // let response = await ApiPW().get(`/providers/index?word=${sword}`);
      let response = await ApiPW().get(`/providers/index`);
      let pwproviders=response.data;
      commit('SET_PWPROVIDERS',pwproviders);  
    }, 
    async loadSalesOrders({commit},sword){
      // let user=JSON.parse(window.localStorage.currentUser);      
      let response = await baseApi().get(`/notaremision/getall?tipo=salesorder&word=${sword}`);
      let salesorders=response.data;
      commit('SET_SALESORDERS',salesorders);        
    }, 
    async loadSalesOrders2({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser); 
      let daterange='';
      if (attribute.date_start=="" || attribute.date_start=="undefined") {
        daterange='';
      }else{
        daterange=attribute.date_start+"|"+attribute.date_end;        
      }     
      let response = await baseApi().get(`/notaremision/getall?tipo=salesorder&valor=${daterange}&word=${attribute.sword}`);
      let salesorders=response.data;
      commit('SET_SALESORDERS',salesorders);        
    },   
    async loadPayments({commit},attribute){
      //let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("Payments: "+attribute.sword);
      if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/pagos/getall`);
        // response = await baseApi().get(`/proveedores/getall?branch_id=1`);
      }else{
        //response = await baseApi().get(`/pagos/getall?valor=${daterange}&word=${attribute.sword}`);
        response = await baseApi().get(`/pagos/getall?word=${attribute.sword}`);
      }      
      let payments=response.data;
      commit('SET_PAYMENTS',payments);        
    },  
    async loadPlugins({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response="";
      console.log("Plugins: "+attribute.sword);
      if (attribute.sword=="All"){ //Funciona para los combo box
        response = await baseApi().get(`/plugins/getall`);
        // response = await baseApi().get(`/plugins/getall?branch_id=${user.branch_id}`);
        // response = await baseApi().get(`/proveedores/getall?branch_id=1`);
      }else{
        response = await baseApi().get(`/plugins/getall?page=1&limit=${attribute.limit}&word=${attribute.sword}`);
        // response = await baseApi().get(`/plugins/getall?page=1&branch_id=${user.branch_id}&limit=${attribute.limit}&word=${attribute.sword}`);
      }      
      let plugins=response.data;
      commit('SET_PLUGINS',plugins);        
    },   
    async loadPurchases({commit},sword){
      // let user=JSON.parse(window.localStorage.currentUser);      
      let response = await baseApi().get(`/compras/getall?tipo=purchases&word=${sword}`);
      
      let purchases=response.data;
      commit('SET_PURCHASES',purchases);        
    },
    async loadPurchases2({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);      
      let daterange='';
      let response="";

      if (attribute.date_start=="" || attribute.date_start=="undefined") {
        daterange='';
      }else{
        daterange=attribute.date_start+"|"+attribute.date_end;        
      }     

      if (attribute.pricelistid){
        response = await baseApi().get(`/compras/getall?valor=${daterange}&word=${attribute.sword}&tipo=${attribute.tipo}&pricelistid=${attribute.pricelistid}`);
      }else if (attribute.projectid){
        response = await baseApi().get(`/compras/getall?valor=${daterange}&word=${attribute.sword}&tipo=${attribute.tipo}&projectid=${attribute.projectid}`);
      }else{
        response = await baseApi().get(`/compras/getall?tipo=purchases&valor=${daterange}&word=${attribute.sword}`);
      }

      let purchases=response.data;
      commit('SET_PURCHASES',purchases);        
    },   
    async loadQuotes({commit},sword){
      let response="";
      // let user=JSON.parse(window.localStorage.currentUser);      
      //let response = await baseApi().get(`/cotizaciones/getall?valor=2021-01-01|2023-12-31&tipo=quotes&word=${sword}`);
      // if (attribute.pricelistid){
      // }else{
        response = await baseApi().get(`/cotizaciones/getall?tipo=quotes&word=${sword}`);
      // }

      
      let quotes=response.data;
      commit('SET_QUOTES',quotes);        
    },  
    //Se utiliza solo en ProductSalesOrder
    async loadQuotes2({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);     
      let response="";
      let daterange='';      

      if (attribute.date_start=="" || attribute.date_start=="undefined") {
        daterange='';
      }else{
        daterange=attribute.date_start+"|"+attribute.date_end;        
      }  


      if (attribute.pricelistid){
        response = await baseApi().get(`/cotizaciones/getall?valor=${daterange}&word=${attribute.sword}&tipo=${attribute.tipo}&pricelistid=${attribute.pricelistid}`);
      }else{
        response = await baseApi().get(`/cotizaciones/getall?tipo=quotes&valor=${daterange}&word=${attribute.sword}`);
      }

      let quotes=response.data;
      commit('SET_QUOTES',quotes);        
    },
    async loadInvoices({commit},sword){
      // let user=JSON.parse(window.localStorage.currentUser);      
      let response = await baseApi().get(`/facturas/getall?valor=2021-01-01|2023-12-31&tipo=invoices&word=${sword}`);
      let invoices=response.data;
      commit('SET_INVOICES',invoices);        
    },  
    async loadEvents({commit},attribute){
      // let user=JSON.parse(window.localStorage.currentUser);
      let response = await baseApi().get(`/eventos/getall?word=${attribute.sword}`);
      let events=response.data;
      commit('SET_EVENTS',events);        
    },   
    async loadTips({commit}){      
      //let response = await ApiPW().get(`/tips/index?page=1&limit=7${attribute.limit}`);
      // let response = await ApiPW().get(`/tips/index?page=1&limit=7`);      
      let response = await baseApi().get(`/notificaciones/tips`);
      let tips=response.data;
      commit('SET_TIPS',tips);        
    },   
    async loadVideos({commit}){      
      //let response = await ApiPW().get(`/tips/index?page=1&limit=7${attribute.limit}`);
      let response = await baseApi().get(`/notificaciones/videos`);
      let videos=response.data;
      commit('SET_VIDEOS',videos);        
    },   
    async loadNotifications({commit}){      
      // try{
        let response = await baseApi().get(`/notificaciones/events`);
        let notifications=response.data;
        commit('SET_NOTIFICATIONS',notifications);        
      // }catch{
      //   router.push('/');
      // }
      
    },   
    async loadCar({commit}){      
      // try{
        let response = await baseApi().get(`/carrito/getall`);
        let car=response.data;
        commit('SET_CAR',car);        
      // }catch{
      //   router.push('/');
      // }
      
    },   
  }  
})

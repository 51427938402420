import axios from "axios";
// var r=JSON.parse(window.localStorage.currentUser);
//Cuando carga por primera vez no muestra nada la APP

let baseApi= axios.create({
    baseURL: 'https://www.cloudbits.org.mx/fw/lumen/check/'
    // baseURL: 'http://127.0.0.1:8082/'
});

const Api =function(){
    let token = localStorage.getItem('token');
    // let token=JSON.parse(window.localStorage.token);
    if (token){
        baseApi.defaults.headers.common = { 
            // Accept:"application/json",
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Headers': '*',
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+token
        };
    }

    return baseApi;
}

export default Api
<template>
  <div id="Breadcrumbs">            
          <nav>
            <v-row  class="nav-wrapper">
                <v-col cols="12">
                  <router-link to="/" class="breadcrumb">Dashboard</router-link>
                  <span class="breadcrumb"> {{ breadcrumbs.page }}</span>                  
                </v-col>
            </v-row>
          </nav>
          <v-container >    
          <h4 class="breadcrumbs-title">{{breadcrumbs.title}}</h4>
          <h6 class="breadcrumbs-title">{{breadcrumbs.description}}</h6>         
          </v-container >    
          <v-divider/>                             
          <br><br>                      
        
  </div>
</template>

<script>
import{ mapState } from "vuex";

export default {
  name: "Breadcrumbs",
  computed:{
    ...mapState(['breadcrumbs']),
  },
  data() {
    return {
      
      
      
    };
  }
};
</script>
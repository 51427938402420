//CON TOKEN

// import axios from "axios";

// let baseApiPW= axios.create({
//     baseURL:'https://www.pwallet.online/api',
// });


// const Api =function(){
//     //let token = localStorage.getItem('token');
//     //let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvd3d3LmNsb3VkYml0cy5vcmcubXhcL2Z3XC9sdW1lblwvY2hlY2tcL2xvZ2luIiwiaWF0IjoxNjM3ODc5MTExLCJleHAiOjE2Mzc4ODI3MTEsIm5iZiI6MTYzNzg3OTExMSwianRpIjoiQjc1ZkV3cFNTd1JsMmhCNiIsInN1YiI6MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.Qf_X2VUTZhk6LNaN1FLz12_wnFunuajclYq3V7dBf5k"
//     //let token=JSON.parse(window.localStorage.token);
//     //if (token){
//         // baseApi.defaults.headers.common['Authorization'] = 'Bearer ${token}'
//         baseApiPW.defaults.headers.common = {
//             // Accept:"application/json",
//             // 'Access-Control-Allow-Origin': '*',
//             // 'Access-Control-Allow-Headers': '*',
//             "Content-Type": "application/json"
//             //'Authorization': 'Bearer '+token
//         };
//     //}

//     return baseApiPW;
// }

// export default Api
//FIN CON TOKEN


//SIN TOKEN

import axios from "axios";


export default()=>{
    return axios.create({
        // baseURL:'https://www.pocketwallet.xyz/api',
        // baseURL:'http://www.pwweb.xyz/api',
        // baseURL:'https://www.pwallet.online/api',
        baseURL:'https://www.pkwallet.shop/api',
        //withCredentials:false,
        //timeout: 10000,
        // headers: {
        //     Accept:"application/json",
        //     'Access-Control-Allow-Origin': '*',
        //     'Access-Control-Allow-Headers': '*',
        //     "Content-Type": "application/json"
        //     //"Api-Token": 'gRTjsp26Gu27KVq0oZco24q3TmEayjM0QFlIrjm5WHZOBWCnK2GvAA4FYaxr',
        // }
    });
}
//FIN SIN TOKEN
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        // dark: true,               
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        // error: '#FF5252',
        error: '#fb4c51',
        info: '#2196F3',
        success: '#4CAF50',
        warning:"#FFC107"
      },
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
      },
});

<template>
 <div id="car">
  <v-menu
        
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              color="orange"
              :content=car.length
              overlap
              offset-x="21"
              offset-y="21"
            >
              <v-btn
                icon
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>add_shopping_cart</v-icon>
              </v-btn>
            </v-badge>
          </template> 
            
          <v-card>
              <v-list>
                <v-list-item>                             
                  <!-- <v-list-item-avatar> -->
                    <!-- <v-img
                                          
                      max-height="150"
                      max-width="150"
                                           
                      lazy-src="/img/avatar/avatar-1.png"
                      src="/img/avatar/avatar-1.png" 
                    ></v-img>                    
                  </v-list-item-avatar>      -->

                  <v-list-item-content>
                    <v-list-item-title>Carrito</v-list-item-title>
                    <v-list-item-subtitle>Convertir en Requision, Salida o Compra</v-list-item-subtitle>
                  </v-list-item-content>      

                  <v-list-item-action>
                    {{car.length}} productos
                  </v-list-item-action>
                </v-list-item>           
              </v-list>     

              <v-divider></v-divider> 
              <v-list> 
                <v-list-item v-for="(row,index) in car " :key="index">
                  <v-list-item-avatar>
                    <v-img
                        :src="row.pricelist.filename"
                        :lazy-src="row.pricelist.filename"
                        :alt="`${row.pricelist.description} avatar`"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        max-height="95"
                        max-width="95"
                    ></v-img>                    
                  </v-list-item-avatar>      
                  <!-- <v-list-item-icon> -->
                    <!-- <v-icon> -->
                      <!-- <v-btn icon @click="UpdateStatus(row.id)">
                        <v-icon>check_circle</v-icon>
                      </v-btn> -->
                    <!-- </v-icon> -->
                  <!-- </v-list-item-icon> -->
                  <v-list-item-content>
                    <v-list-item-title >{{ getTextShort(row.pricelist.description) }}</v-list-item-title>
                    <v-list-item-title >
                      <small>SKU: {{ row.pricelist_id }}</small>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text v-text="row.qty"></v-list-item-action-text>
                    <v-list-item-action-text>{{ row.price | currency }}</v-list-item-action-text>
                      <!-- <v-icon
                        v-if="!active"
                        color="grey lighten-1"
                      >
                        mdi-star-outline
                      </v-icon> -->

                  </v-list-item-action>
                </v-list-item>
                
              </v-list>

              <v-divider></v-divider>     
             
              <v-card-actions>
                <v-spacer></v-spacer>  
                <v-btn text @click="getCar">
                  Actualizar
                </v-btn>
               

                <router-link to="/carrito"> 
                  <v-btn text>Ir al Carrito</v-btn>                  
                </router-link>
                <!-- <v-btn color="primary" text @click="menu = false">Guardar</v-btn> -->
              </v-card-actions>
          </v-card>
  </v-menu>

  
    
 </div>
</template>


<script>
// import axios from "axios";
import{ mapMutations,mapState } from "vuex";
import baseApi from '@/services/baseApi';
// import router from '@/router'

export default{
  name: "car",  
  computed:{
        ...mapState(['car'])
  },
  methods:{
    ...mapMutations(["showLoading","hiddenLoading"]),
    getTextShort(text){
      if (text.length > 35) {
       return text.slice(0, 35)+'...'
      }else return text
    },
    async UpdateStatus(id){
      // alert("Estoy aqui"+id)
      try{        
            this.showLoading({titulo:"Accediendo información",color:"warning"})        
            baseApi().post(`/eventos2/update/${id}`,JSON.stringify({'status':'Contactado'})).then((response)=>{              
                if(!response.data.error){                            
                  this.$swal("Genial!",response.data.message, "success");
                }else{
                  this.$swal("Error!",response.data.message, "error");
                }                                    
                // console.log("VENTA: "+response.data.message);
            });                                                                                                    
            // console.log(datos);
      }catch(error){
            console.log('Car '+error);
      }  finally{
            this.hiddenLoading()
      }
    },
    async getCar(){          
      try{
        this.showLoading({titulo:"Accediendo información",color:"primary"})
        await this.$store.dispatch('loadCar'); 
        // console.log("Car:"+this.car)        
      }catch(error){
            console.log('Car '+error)
      }  finally{
        this.hiddenLoading()
      } 
    }    
  },
  data(){
    return{
        revents:null,
    }
  },
  created(){
    this.getCar()
  } 
}
</script>
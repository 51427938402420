import axios from "axios";
import router from '@/router';
// import VueSwal from 'vue-swal';
// import VueSweetalert2 from 'vue-sweetalert2';



//Cuando carga por primera vez no muestra nada la APP
// var r=JSON.parse(window.localStorage.currentUser);

// let icheckApi= axios.defaults.baseURL = 'http://127.0.0.1:8082/'
let icheckApi= axios.create({
  baseURL: 'https://www.cloudbits.org.mx/fw/lumen/check/'
  // baseURL: 'http://127.0.0.1:8082/'
});

const Api =function(){
  // let token = localStorage.getItem('token');
  // let token=JSON.parse(window.localStorage.token);

    // Request interceptor
    icheckApi.interceptors.request.use((config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
    })

    // Response interceptor
    icheckApi.interceptors.response.use((response) => {
      // Handle the response here
      return response
    }, (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            this.$swal(error.response.data.message, {
              closeOnClickOutside: false
            }); //showing Swal Alert
            break;
          case 401:
            // this.$swal("Genial!",error.response.data.message, "success");

            // this.$swal(error.response.data.message, {
            //   closeOnClickOutside: false
            // });
            // alert('An error occurred. Please try again later.')

            router.push('/login')
            break;
          case 500:
              // this.$swal("Error!",error.response.data.message+"<br/> Intente Nuevamente o verifique todos los campos", "error");
              alert(error.response.data.message+'An error occurred. Please try again later.')

              // this.$swal(error.response.data.message, {
              //   closeOnClickOutside: false
              // }); //showing Swal Alert
              break;
          default:
            alert('An error occurred. Please try again later.')
            // this.$swal("Error!", "Algo salio mal, Intente nuevamente <br/>"+error.response.data.message, "error");
            break;
        }
      }
      // Handle errors here
      console.error(error)
      return Promise.reject(error)
    })



    return icheckApi;
}

export default Api